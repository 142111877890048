<template>
  <div :class="`confirm confirm${id}`" :style="style">
    <Spacer num="2.8" v-if="$loading">
      <div class="confirm-loaderMessage" v-if="loadingMessage">{{ loadingMessage }}</div>
      <PreloadingLine class="confirm-loader" inline />
    </Spacer>
    <div class="confirm-content" v-else>
      <button class="confirm-button __cancel sec-button" @click="cancel" v-if="cancelButton">
        <span class="icon" v-if="cancelButton.icon"><iconic :name="cancelButton.icon" /></span>
        <span>{{ cancelButton.label }}</span>
      </button>
      <button class="confirm-button __accept primaryButton" @click="accept" v-if="acceptButton">
        <span class="icon" v-if="acceptButton.icon"><iconic :name="acceptButton.icon" /></span>
        <span>{{ acceptButton.label }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["confirm"],
  data: function() {
    return { style: "opacity:0;" };
  },
  methods: {
    init: async function() {
      this.confirmButton = await Go.awaitUntilElement(`.confirm${this.id} .__accept`);
      this.style = `--confirm-button-width: ${this.confirmButton.offsetWidth}px;`;
      this.style += `--confirm-button-height: ${this.confirmButton.offsetHeight}px;`;
    },
    accept: function() {
      this.$emit("accept");

      if (Go.is(this.acceptButton.action, "function")) {
        this.acceptButton.action();
      }
    },
    cancel: function() {
      this.$emit("cancel");

      if (Go.is(this.cancelButton.action, "function")) {
        this.cancelButton.action();
      }
    },
  },
  beforeMount: function() {
    this.init();
  },
  computed: {
    id: function() {
      return Go.fastID();
    },
    acceptButton: function() {
      return this.confirm && this.confirm.accept;
    },
    cancelButton: function() {
      return this.confirm && this.confirm.cancel;
    },
    loadingMessage: function() {
      return this.confirm && this.confirm.loadingMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  position: relative;
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: $mpadding;
  }
  &-button {
    user-select: none;
    display: flex;
    align-items: center;
    gap: $mpadding/2;
    justify-content: center;
    text-align: center;
    min-height: var(--confirm-button-height);
    border: none;
    appearance: none;
  }
  &-button:active {
    opacity: 0.4;
  }
  &-button .iconic {
    display: flex;
    align-items: center;
  }
  &-loader {
    top: 50%;
  }
}
</style>
